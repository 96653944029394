@charset "UTF-8";

@import "_setting";

/* =====================
	TOPページ
 ======================= */
.cmn_ttl {
	&_jp {
		font-size: 22px;
	}
}

.bg_video {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	
	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.mv {
	height: 100vh;
	
	&_inner {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 100%;
	}
	
	&_ttl {
		color: $c_wht;
		font-size: 32px;
		font-weight: 700;
		letter-spacing: 0.17em;
		
		img {
			margin-bottom: 24px;
		}
		
		&.anim {
			transition-delay: 0.5s;
		}
	}
}

.company {
	overflow: hidden;
	
	&_ttl {
		font-family: $font_eng;
		font-size: 45px;
		font-weight: 700;
		letter-spacing: 0.17em;
		line-height: 1.2;
		padding-bottom: 20px;
		border-bottom: 1px solid $c_text;
		margin-bottom: 20px;
	}
	
	&_catch {
		font-size: 25px;
		font-weight: 700;
		line-height: (40 / 25);
		margin-bottom: 80px;
		
		&.anim {
			transition-delay: .5s;
		}
	}
	
	&_container {
		position: relative;
		z-index: 2;
		
		&.anim {
			transition-delay: .5s;
		}
	}
	
	&_txt {
		max-width: 500px;
		color: $c_sub;
		font-size: 18px;
		line-height: (26 / 18);
	}
	
	&_img {
		margin-top: 84px;
		padding-bottom: 64px;
		
		&01 {
			position: absolute;
			top: 0;
			left: (575 / 1000 * 100%);
			width: max-content;
			z-index: -1;
			
			&.anim {
				opacity: 1.0;
				overflow: hidden;
				
				&:before {
					content: "";
					width: 100%;
					height: 100%;
					background: $c_wht;
					position: absolute;
					left: 0;
					top: 0;
					transition: $anim;
					transition-delay: 0.3s;
				}
				
				&.is-show {
					&:before {
						transform: translateX(100%);
					}
				}
			}
		}
		
		&02 {
			position: relative;
			max-width: 617px;
			
			&.anim {
				opacity: 1.0;
				overflow: hidden;
				
				&:before {
					content: "";
					width: 100%;
					height: 100%;
					background: $c_wht;
					position: absolute;
					left: 0;
					top: 0;
					transition: $anim;
					transition-delay: 0.35s;
				}
				
				&.is-show {
					&:before {
						transform: translateX(-100%);
					}
				}
			}
		}
	}
}

section.service {
	.cmn_ttl {
		&_eng {
			font-size: 45px;
		}
	}
}

section.news {
	background: url(../img/home/news_bg.jpg) no-repeat center /cover;
	
	.cmn_ttl {
		&_eng {
			font-size: 60px;
		}
	}
	
	.news_list {
		&_item {
			a {
				color: $c_wht;
				
				.cat {
					background: none;
					border: 1px solid currentColor;
				}
			}
		}
	}
}

/* スマホ
  ------------------------ */
@media (max-width: $breakpoint_sp) {
	.cmn_ttl {
		&_jp {
			font-size: sp(14);
		}
	}
	
	.mv {
		&_inner {
			align-items: flex-end;
			padding-bottom: 5%;
		}
		
		&_ttl {
			font-size: sp(17.2);
			
			img {
				width: (310 / 360 * 100%);
			}
		}
	}
	
	.company {
		&_ttl {
			font-size: sp(30);
			letter-spacing: 0.1em;
			position: relative;
			
			&:after {
				content: "";
				width: 100%;
				height: 1px;
				background: $c_text;
				position: absolute;
				bottom: -1px;
				left: 100%;
			}
		}
		
		&_catch {
			font-size: sp(14);
			margin-bottom: 40px;
		}
		
		&_container {
		}
		
		&_txt {
			font-size: sp(12);
			max-width: none;
		}
		
		&_img {
			margin-top: 40px;
			padding: 0;
			position: relative;
			
			&01 {
				position: relative;
				left: auto;
				width: (250 / 400 * 100%);
				margin-left: auto;
				z-index: 0;
			}
			
			&02 {
				position: absolute;
				left: 0;
				bottom: 25px;
				width: (225 / 400 * 100%);
				z-index: 2;
			}
		}
	}
	
	section.service {
		.cmn_ttl {
			&_eng {
				font-size: sp(30);
			}
		}
	}
	
	section.news {
		.cmn_ttl {
			&_eng {
				font-size: sp(30);
			}
		}
		
		.news_list {
			&_item {
				margin-bottom: 20px;
				
				a {
					column-gap: 12px;
					
					.cat {
						width: (90 / 360 * 100%);
						font-size: sp(14)
					}
					
					.date {
						font-size: sp(12);
					}
					
					.ttl {
						font-size: sp(12);
					}
				}
			}
		}
	}
}