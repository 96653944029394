// カラー定義
$c_text:#111011;
$c_wht: #fff;
$c_sub: #666066;

// フォント定義
$font_ja:'Noto Sans JP', sans-serif;
$font_eng: 'Roboto Condensed', sans-serif;

// ブレークポイント
$breakpoint_sp:899px;
$breakpoint_pc:$breakpoint_sp + 1;

$anim: all 0.4s;

@function vw($px) {
  $vw : ($px / 1920 * 100);
  @return ($vw)+vw;
  //@return ($px)+px;
}

@function rem($px) {
  $rem : ($px / 16);
  @return ($rem)+rem;
  //@return ($rem)+px;
}

@function per($px, $parent:1920) {
  $per : ($px / $parent * 100%);
  @return ($per);
  //@return ($px)+px;
}

@function fs($px, $parent:18) {
  $fs : ($px / $parent * 100%);
  @return ($fs);
}


@function sp($px) {
  $size : ($px / 400 * 100vw);
  //@return ($size);
  @return ($px)+px;
}
