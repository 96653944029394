@charset "UTF-8";
/* =====================
	TOPページ
 ======================= */
.cmn_ttl_jp {
  font-size: 22px;
}

.bg_video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg_video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mv {
  height: 100vh;
}

.mv_inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.mv_ttl {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0.17em;
}

.mv_ttl img {
  margin-bottom: 24px;
}

.mv_ttl.anim {
  transition-delay: 0.5s;
}

.company {
  overflow: hidden;
}

.company_ttl {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.17em;
  line-height: 1.2;
  padding-bottom: 20px;
  border-bottom: 1px solid #111011;
  margin-bottom: 20px;
}

.company_catch {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.6;
  margin-bottom: 80px;
}

.company_catch.anim {
  transition-delay: .5s;
}

.company_container {
  position: relative;
  z-index: 2;
}

.company_container.anim {
  transition-delay: .5s;
}

.company_txt {
  max-width: 500px;
  color: #666066;
  font-size: 18px;
  line-height: 1.44444;
}

.company_img {
  margin-top: 84px;
  padding-bottom: 64px;
}

.company_img01 {
  position: absolute;
  top: 0;
  left: 57.5%;
  width: max-content;
  z-index: -1;
}

.company_img01.anim {
  opacity: 1.0;
  overflow: hidden;
}

.company_img01.anim:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.4s;
  transition-delay: 0.3s;
}

.company_img01.anim.is-show:before {
  transform: translateX(100%);
}

.company_img02 {
  position: relative;
  max-width: 617px;
}

.company_img02.anim {
  opacity: 1.0;
  overflow: hidden;
}

.company_img02.anim:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.4s;
  transition-delay: 0.35s;
}

.company_img02.anim.is-show:before {
  transform: translateX(-100%);
}

section.service .cmn_ttl_eng {
  font-size: 45px;
}

section.news {
  background: url(../img/home/news_bg.jpg) no-repeat center/cover;
}

section.news .cmn_ttl_eng {
  font-size: 60px;
}

section.news .news_list_item a {
  color: #fff;
}

section.news .news_list_item a .cat {
  background: none;
  border: 1px solid currentColor;
}

/* スマホ
  ------------------------ */
@media (max-width: 899px) {
  .cmn_ttl_jp {
    font-size: 14px;
  }
  .mv_inner {
    align-items: flex-end;
    padding-bottom: 5%;
  }
  .mv_ttl {
    font-size: 17.2px;
  }
  .mv_ttl img {
    width: 86.11111%;
  }
  .company_ttl {
    font-size: 30px;
    letter-spacing: 0.1em;
    position: relative;
  }
  .company_ttl:after {
    content: "";
    width: 100%;
    height: 1px;
    background: #111011;
    position: absolute;
    bottom: -1px;
    left: 100%;
  }
  .company_catch {
    font-size: 14px;
    margin-bottom: 40px;
  }
  .company_txt {
    font-size: 12px;
    max-width: none;
  }
  .company_img {
    margin-top: 40px;
    padding: 0;
    position: relative;
  }
  .company_img01 {
    position: relative;
    left: auto;
    width: 62.5%;
    margin-left: auto;
    z-index: 0;
  }
  .company_img02 {
    position: absolute;
    left: 0;
    bottom: 25px;
    width: 56.25%;
    z-index: 2;
  }
  section.service .cmn_ttl_eng {
    font-size: 30px;
  }
  section.news .cmn_ttl_eng {
    font-size: 30px;
  }
  section.news .news_list_item {
    margin-bottom: 20px;
  }
  section.news .news_list_item a {
    column-gap: 12px;
  }
  section.news .news_list_item a .cat {
    width: 25%;
    font-size: 14px;
  }
  section.news .news_list_item a .date {
    font-size: 12px;
  }
  section.news .news_list_item a .ttl {
    font-size: 12px;
  }
}
